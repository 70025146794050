import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import PrivateRoute from './Secure';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */

const AllPartners = Loadable(lazy(() => import("../../src/views/partner/AllPartners")));
const AddPartner = Loadable(lazy(() => import("../../src/views/partner/AddPartner")));
const Error = Loadable(lazy(() => import("../../src/views/authentication/Error")));
const Login = Loadable(lazy(() => import("../../src/views/authentication/Login")));

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/auth/login" /> }, 
      { path: "Partners", element: <PrivateRoute element={AllPartners} /> }, 
      { path: "AddPartner", element: <PrivateRoute element={AddPartner} /> }, 
      { path: "*", element: <Navigate to="/auth/404" /> }, 
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <Login /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
