export const FETCH_PARTNERS_REQUEST = 'FETCH_PARTNERS_REQUEST';
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS';
export const FETCH_PARTNERS_FAILURE = 'FETCH_PARTNERS_FAILURE';

export const CREATE_PARTNER_REQUEST = 'CREATE_PARTNER_REQUEST';
export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const CREATE_PARTNER_FAILURE = 'CREATE_PARTNER_FAILURE';

export const DELETE_PARTNER_REQUEST = 'DELETE_PARTNER_REQUEST';
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
export const DELETE_PARTNER_FAILURE = 'DELETE_PARTNER_FAILURE';
