import {
  FETCH_PARTNERS_REQUEST,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  CREATE_PARTNER_REQUEST,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_FAILURE,
  DELETE_PARTNER_REQUEST,
  DELETE_PARTNER_SUCCESS,
  DELETE_PARTNER_FAILURE,
} from '../Types/partner';

const initialState = {
  blogs: [],
  loading: false,
  error: null,
};

const partnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTNERS_REQUEST:
    case CREATE_PARTNER_REQUEST:
    case DELETE_PARTNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: action.payload,
      };
    case CREATE_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: [...state.partners, action.payload],
      };
    case DELETE_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: state.partners.filter((partner) => partner.id !== action.payload),
      };
    case FETCH_PARTNERS_FAILURE:
    case CREATE_PARTNER_FAILURE:
    case DELETE_PARTNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default partnersReducer;
